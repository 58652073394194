var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Logo.vue?vue&type=custom&index=0&blockType=svg&version=1.0&xmlns=http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg&width=38.000000pt&height=49.000000pt&viewBox=0%200%2038.000000%2049.000000&preserveAspectRatio=xMidYMid%20meet"
if (typeof block0 === 'function') block0(component)

export default component.exports